import React, { useEffect, useState } from 'react';
import Auth from './pages/auth';
import Main from './pages/main';
import Cookies from 'js-cookie';
import { refreshAccessToken } from './utils/auth';

function App() {
  const [hasCredentials, setHasCredentials] = useState(false);

  useEffect(() => {
    async function checkUserCredentials() {
      const credentials = await checkCredentials();
      console.log('Has Credentials:', credentials);
      setHasCredentials(credentials);
    }
    checkUserCredentials();
  }, []);

  return (
    <div className="App">
      {hasCredentials ? <Main /> : <Auth />}
    </div>
  );
}

async function checkCredentials() {
  const token = Cookies.get('x-token');
  if (token) {
    return true;
  }
  try {
    const result = await refreshAccessToken();
    console.log('Refresh Access Token Result:', result);
    return result;
  } catch (error) {
    console.error('Error refreshing token:', error);
    return false;
  }
}

export default App;