import React from 'react';
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
import { checkSession, login } from '../utils/auth';
import { jwtDecode } from 'jwt-decode';

const Auth = () => {
    const handleGoogleLoginSuccess = async (credentialResponse) => {
        const decodedToken = jwtDecode(credentialResponse.credential);
        const email = decodedToken.email;

        try {
            const { status, message } = await checkSession(email);

            if (status === 226) {
                if (window.confirm("Session already exists. Do you want to takeover?")) {
                    const loginResponse = await login(email);
                    console.log('Login Response:', loginResponse);
                    window.location.href = './main';
                }
            } else if (status === 200) {
                const loginResponse = await login(email);
                console.log('Login Response:', loginResponse);
                window.location.href = './main';
            } else {
                alert(`Error: ${message}`);
            }
        } catch (error) {
            console.error('Error during session check or login:', error);
            googleLogout();
            alert('Login Failed');
        }
    };

    const handleGoogleLoginFailure = () => {
        alert('Login Failed');
    };

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <div className="doodle-bg bg-gray-100 text-gray-900 min-h-screen flex items-center justify-center">
                <style jsx>{`
                    .btn {
                        transition: transform 0.2s, background-color 0.3s;
                        background-color: #4A4A4A;
                        color: #fff;
                        padding: 0.75rem 2rem;
                        font-size: 1rem;
                        border-radius: 1rem;
                        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
                        margin-top: 2rem;
                        width: 100%;
                        text-align: center;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        cursor: pointer;
                    }
                    .btn:hover {
                        transform: scale(1.05);
                        background-color: #2C2C2C;
                    }
                    .doodle-bg {
                        margin: 0;
                        padding: 0;
                        background-image: url('https://images5.alphacoders.com/390/390081.jpg');
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        height: 100vh;
                        width: 100vw;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    body {
                        filter: none;
                    }
                    .form-container {
                        background: rgba(255, 255, 255, 0.9);
                        backdrop-filter: blur(10px);
                        padding: 2rem;
                        border-radius: 1rem;
                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        max-width: 400px;
                    }
                    .input-field {
                        background: rgba(255, 255, 255, 0.8);
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        padding: 0.75rem;
                        border-radius: 0.5rem;
                        width: calc(100% - 1.5rem);
                        margin-top: 0.5rem;
                        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
                        transition: box-shadow 0.3s ease;
                    }
                    .input-field:focus {
                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
                        outline: none;
                    }
                    .label {
                        font-weight: bold;
                    }
                `}</style>
                <div className="form-container">
                    <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Login</h1>
                    <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        onError={handleGoogleLoginFailure}
                        className="btn block text-center bg-gray-700 text-white py-2 px-4 rounded-full shadow-lg hover:bg-gray-800 transition duration-300"
                    />
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default Auth;