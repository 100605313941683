import axios from 'axios';
import { decryptWithAESJWT } from './crypto';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { googleLogout } from '@react-oauth/google';

export async function checkSession(email) {
    try {
        const url = `${process.env.REACT_APP_API_HOST}/v1/auth/check-session`;
        const basicAuth = process.env.REACT_APP_BASIC_AUTH_USERNAME + ":" + process.env.REACT_APP_BASIC_AUTH_PASSWORD;
        const header = {
            'Authorization': "Basic " + btoa(basicAuth),
            'Content-Type': 'application/json'
        };
        const body = {
            "user_email": email
        };
        const response = await axios.post(url, body, { headers: header });
        const status = response.data.status;
        const message = response.data.message;
        return { status, message };
    } catch (error) {
        console.error('Session check failed:', error);
        throw error;
    }
}


export async function login(email) {
    try {
        const url = `${process.env.REACT_APP_API_HOST}/v1/auth/login`;
        const basicAuth = process.env.REACT_APP_BASIC_AUTH_USERNAME + ":" + process.env.REACT_APP_BASIC_AUTH_PASSWORD;
        const header = {
            'Authorization': "Basic " + btoa(basicAuth),
            'Content-Type': 'application/json'
        };
        const body = {
            "user_email": email
        };
        const response = await axios.post(url, body, { headers: header });
        const status = response.data.status;
        const message = response.data.message;
        const data = response.data.data;
        const token = data['x-token'];
        const refreshToken = data['x-refresh-token'];

        const decryptedToken = decryptWithAESJWT(token);
        const decryptedRefreshToken = decryptWithAESJWT(refreshToken);

        const decodedToken = jwtDecode(decryptedToken);
        const decodedRefreshToken = jwtDecode(decryptedRefreshToken);

        const tokenExpiry = decodedToken.exp - Math.floor(Date.now() / 1000);
        const refreshTokenExpiry = decodedRefreshToken.exp - Math.floor(Date.now() / 1000);

        Cookies.set('x-token', token, { expires: tokenExpiry / 86400 });
        Cookies.set('x-refresh-token', refreshToken, { expires: refreshTokenExpiry / 86400 });

        return { status, message };
    } catch (error) {
        console.error('Session check failed:', error);
        throw error;
    }
}

export async function refreshAccessToken() {
    try {
      const refreshToken = Cookies.get('x-refresh-token');
      if (!refreshToken) {
        return false;
      }
      console.log('Refresh Token:', refreshToken);
      const decryptedRefreshToken = decryptWithAESJWT(refreshToken);
      console.log('Decrypted Refresh Token:', decryptedRefreshToken);
  
      const url = `${process.env.REACT_APP_API_HOST}/v1/auth/refresh-token`;
      const headers = {
        'Authorization': `Bearer ${decryptedRefreshToken}`,
        'Content-Type': 'application/json'
      };
  
      const response = await axios.get(url, { headers });
      const newToken = response.data.data['x-token'];
  
      if (newToken) {
        const decryptedNewToken = decryptWithAESJWT(newToken);
        const decodedToken = jwtDecode(decryptedNewToken);
        const tokenExpiry = decodedToken.exp - Math.floor(Date.now() / 1000);
        Cookies.set('x-token', newToken, { expires: tokenExpiry / 86400 });
        console.log('Token refreshed successfully');
        return true;
      }
      return false;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      Cookies.remove('x-token');
      Cookies.remove('x-refresh-token');
      return false;
    }
}

export async function logout() {
    try {
        const token = Cookies.get('x-token');
        if (!token) {
            console.error('No refresh token found');
            return;
        }

        const decryptedToken = decryptWithAESJWT(token);

        const url = `${process.env.REACT_APP_API_HOST}/v1/auth/logout`;
        const headers = {
            'Authorization': `Bearer ${decryptedToken}`,
            'Content-Type': 'application/json'
        };

        // Panggil API
        await axios.delete(url, { headers });
        googleLogout();

        // Hapus token dari cookie
        Cookies.remove('x-token');
        Cookies.remove('x-refresh-token');
        console.log('Logged out successfully');
        window.location.href = './auth';
    } catch (error) {
        if (error.response.status === 401) {
            googleLogout();
            Cookies.remove('x-token');
            Cookies.remove('x-refresh-token');
        }
        alert('Logout failed');
    }
}